import React, { useState, useRef, useEffect } from 'react'
import { useDropzone } from 'react-dropzone';

import Img from "gatsby-image"
import Formsy from 'formsy-react';
import axios from "axios"

import FormsyInput from "./../Formsy/formsyInput"

import { MultiSelect } from "react-multi-select-component";

import htmlDecode from "html-entities-decoder";


import "./ApplicantForm.css";

const overrideCompaniesStrings =
{
    "allItemsAreSelected": "Όλα τα beach bar έχουν επιλεχθεί",
    "clearSearch": " Εκκαθάριση αναζήτησης",
    "clearSelected": " Εκκαθάριση επιλεγμένου beach bar",
    "noOptions": "Καμία επιλογή",
    "search": "Αναζήτηση",
    "selectAll": "Επιλογή όλων",
    "selectAllFiltered": " Επιλογή όλων (Filtered)",
    "selectSomeItems": "Επιλογή beach bar",
    "create": "Δημιουργία",
}

const overrideJobsStrings =
{
    "allItemsAreSelected": "Όλες οι θέσεις έχουν επιλεχθεί",
    "clearSearch": " Εκκαθάριση αναζήτησης",
    "clearSelected": " Εκκαθάριση επιλεγμένης θέσης εργασίας",
    "noOptions": "Καμία επιλογή",
    "search": "Αναζήτηση",
    "selectAll": "Επιλογή όλων",
    "selectAllFiltered": " Επιλογή όλων (Filtered)",
    "selectSomeItems": "Επιλογή θέσης εργασίας",
    "create": "Δημιουργία",
}


function CareerDayForm(props) {
    const data = props;
    const formRef = useRef(null);

    // const [jCompanies, setJCompanies] = useState([]);
    const [jJobs, setJJobs] = useState([
        {
            value: "Υπεύθυνος μπαρ",
            label: "Υπεύθυνος μπαρ"
        },
        {
            value: "Βοηθός μπαρ",
            label: "Βοηθός μπαρ"
        },
        {
            value: "Ταμίας",
            label: "Ταμίας"
        },
        {
            value: "Βοηθός Κουζίνας",
            label: "Βοηθός Κουζίνας"
        },
        {
            value: "Υπεύθυνος Καταστήματος",
            label: "Υπεύθυνος Καταστήματος"
        },
        {
            value: "Σερβιτόρος - Πωλητής",
            label: "Σερβιτόρος - Πωλητής"
        },
        {
            value: "Σερβιτόρος",
            label: "Σερβιτόρος"
        },
        {
            value: "Βοηθός Παραλίας",
            label: "Βοηθός Παραλίας"
        },
        {
            value: "Υποδοχή",
            label: "Υποδοχή"
        },
        
    ]);

    const [canSubmit, setCanSubmit] = useState(false);
    const [canSubmit2, setCanSubmit2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [privacyTermsStatus, setPrivacyTermsStatus] = useState(false);
    const [subsStatus, setSubsStatus] = useState(false);
    const [cvOptIn, setCvOptIn] = useState(false);

    const [files, setFiles] = useState([]);
    const [emailSent, setEmailSent] = useState(false);
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailResponse, setEmailResponse] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [selectedCompany, setSelectedCompany] = useState([]);
    const [selectedJobs, setSelectedJobs] = useState([]);



    useEffect(() => {
        let companies = [];


        // data.jobCompanies.edges.map((jc, i) => {
        //     if (jc.node.name.includes("CareerDays")) {
        //         let oJobs = [];
        //         jc.node.ourJobs.edges.map((pos, j) => {
        //             oJobs.push({
        //                 label: htmlDecode(pos.node.title),
        //                 value: pos.node.slug
        //             })
        //         })


        //         companies.push({
        //             label: jc.node.name.replace("-CareerDays", ""),
        //             value: jc.node.name.replace("-CareerDays", ""),
        //             jobs: oJobs ?? []
        //         })

        //     }
        // })

        // console.log("!!!!!!!!!!!!!")
        // console.log(companies)
        // setJCompanies([...companies])


    }, [props])

    // useEffect(() => {
    //     console.log("Selected Companies")
    //     console.log(selectedCompany)
    //     let tmp = [];
    //     selectedCompany.map((sc, i) => {
    //         sc.jobs.map((scj, j) => {
    //             if (!(tmp.filter(e => e.label === scj.label).length > 0)) {
    //                 tmp.push(scj)
    //             }
    //         })
    //     })

    //     setJJobs([...tmp])
    // }, [selectedCompany])

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
        console.log(files)
    }, [files]);

    useEffect(() => {
        if (canSubmit) {
            setShowErrorMessage(false)
        }
    }, [canSubmit])

    useEffect(() => {

        console.log(!cvOptIn)
        console.log(!props.allowCv)
        console.log(files.length)
        console.log(privacyTermsStatus && canSubmit && (!cvOptIn && (!props.allowCv && files.length <= 0)))

        if (privacyTermsStatus && canSubmit && (!cvOptIn && (!props.allowCv && files.length <= 0))) {
            setCanSubmit2(true)
        } else if ((privacyTermsStatus && canSubmit && (cvOptIn && files.length > 0))) {
            setCanSubmit2(true)
        } else {
            setCanSubmit2(false)
        }
    }, [privacyTermsStatus, cvOptIn, canSubmit ])

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'application/pdf,.doc,.docx',
        maxSize: 5242880,
        multiple: false,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        },
        onDropRejected: (e) => {
            console.log(e)
            console.log("Invoked");
        }
    });



    function disableButton() {
        setCanSubmit(false);
    }

    function enableButton() {
        setCanSubmit(true);
    }

    function submit(model) {
        console.log(model, files)

        if (model.lastName && model.name && model.email && files.length > 0) {
            sendApplication(model, files);
        } else if ((model.lastName && model.name && model.email) && (files.length > 0)) {
            sendApplication(model, files);
        } else if (model.lastName && model.name && model.email) {
            sendApplication(model);
        } else {
            setShowErrorMessage(true)
        }
    }

    function sendApplication(model, files) {
        setLoading(true)

        console.log(model)

        let formData = new FormData();
        if (files) {
            formData.append('file', files[0]);
        }
        formData.append('model', JSON.stringify(model));

        formData.append('jobName', 'Ημέρες Καρίερας');

        formData.append('subscribed', subsStatus);


        // let tmpCompanies = []

        // selectedCompany.map((sc, i) => {
        //     let tmpLabel = sc.label + "-CareerDays";
        //     tmpCompanies.push(tmpLabel.toLowerCase())
        // })

        // formData.append('companiesApplied', tmpCompanies);

        let tmpJobs = []

        selectedJobs.map((sj, i) => {
            tmpJobs.push(sj.label)
        })

        formData.append('jobsApplied', tmpJobs);

        console.log(formData);

        axios.post(`https://f2.enimeris.com/wp-json/myplugin/v1/addApplicantCareerDays`, formData)
            .then(function (response) {
                console.log(response)
                // handle success
                setEmailFailed(false)
                setLoading(false)
                setEmailSent(true)
                setEmailResponse(response.data)
            })
            .catch(function (error) {
                // handle error
                setLoading(false)
                setEmailFailed(true)
                setEmailResponse("Υπήρξε πρόβλημα κατα την αποστολή. Δοκιμάστε αργότερα")
            })
    }

    function test(e) {
        e.preventDefault();

        console.log("cansubmit1: " + canSubmit)
        console.log("cansubmit2: " + canSubmit2)

        setShowErrorMessage(true)
    }

    function resetFile() {
        setFiles([]);
    }

    return (
        <div className={`${emailSent ? "email-sent-container" : "cf-container"} py-24`}>
            <div className={`m-auto ${emailSent ? "max-w-none" : "max-w-xl"} px-12 relative`} >
                {loading && (
                    <div className="loader-container">
                        <div className="loader">Loading...</div>
                    </div>
                )}

                {emailFailed && (
                    <p style={{ color: "#b70000", fontSize: "1.2rem" }}>{emailResponse}</p>
                )}

                {!emailSent
                    ?
                    <Formsy
                        onValidSubmit={submit}
                        onValid={enableButton}
                        onInvalid={disableButton}
                        className={loading ? "opacity-25" : "opacity-100"}
                        ref={formRef}
                    >
                        <h2 className="text-4xl text-white text-center">
                            {data.textData.title}
                        </h2>
                        <div>
                            <p className="text-md text-white text-center">
                                {data.textData.subtitle}
                            </p>
                        </div>
                        <div className="mt-12">
                            <FormsyInput
                                name="name"
                                placeholder="Όνομα *"
                                classes="mt-4"
                                required
                            />
                            <FormsyInput
                                name="lastName"
                                placeholder="Επώνυμο *"
                                classes="mt-4"
                                required
                            />
                            <FormsyInput
                                name="email"
                                validations={"isEmail"}
                                validationErrors={{
                                    isEmail: "Το email δεν είναι έγκυρο",
                                }}
                                placeholder="Email *"
                                classes="mt-4"
                                required
                            />
                            {props.allowPhoneNumber && (
                                <FormsyInput
                                    name="telephone"
                                    validations={"minLength:10"}
                                    type="number"
                                    validationErrors={{
                                        minLength: "Το τηλέφωνο δεν είναι έγκυρο",
                                    }}
                                    placeholder="Τηλέφωνο *"
                                    classes="mt-4"
                                    required
                                />

                            )}

                            {console.log(selectedCompany)}

                            {/* {(jCompanies && jCompanies.length > 0) && (
                                <div className='py-4'>
                                    <MultiSelect
                                        options={jCompanies}
                                        value={selectedCompany}
                                        onChange={setSelectedCompany}
                                        labelledBy="Εταιρείες"
                                        overrideStrings={overrideCompaniesStrings}
                                    />
                                </div>
                            )} */}

                            {(jJobs && jJobs.length > 0) && (
                                <div className='py-4'>
                                    <MultiSelect
                                        options={jJobs}
                                        value={selectedJobs}
                                        onChange={setSelectedJobs}
                                        labelledBy="Select"
                                        overrideStrings={overrideJobsStrings}

                                    />
                                </div>
                            )}




                            <div className="text-white" {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} />
                                <img src={props.icon ? props.icon : null} style={{ width: "150px" }} />
                                <p>Μεταφόρτωση Βιογραφικού {props.allowCv ? "*" : ""}</p>

                                {files.length > 0 && (
                                    <span>
                                        {files[0].name}
                                    </span>
                                )}
                            </div>

                            {files.length > 0 && (
                                <div onClick={resetFile} className="clear-cv-w">Καθαρισμός</div>
                            )}

                            <div className="mt-8">
                                <input
                                    name="privacy-terms"
                                    type="checkbox"
                                    checked={privacyTermsStatus}
                                    onChange={() => setPrivacyTermsStatus(!privacyTermsStatus)}
                                />
                                <span className="pl-2 text-white">Αποδέχομαι την
                                    <a href="https://enimeris.com/politiki-aporitou/" target="_blank" className="px-1 underline">Πολιτική Απορρήτου</a>
                                    και τους
                                    <a href="https://enimeris.com/oroi-kai-proipotheseis/" target="_blank" className="pl-1 underline">Όρους και Προϋποθέσεις Χρήσης</a>. *
                                </span>
                            </div>

                            <div className="mt-4">
                                <input
                                    name="subscription"
                                    type="checkbox"
                                    checked={subsStatus}
                                    onChange={() => setSubsStatus(!subsStatus)}
                                />
                                <span className="pl-2 text-white">
                                    Επιθυμώ να ενημερώνομαι για υπηρεσίες και δράσεις της Enimeris. 
                                </span>
                            </div>

                            {files.length > 0 && (
                                <div className="mt-4">
                                    <input
                                        name="privacy-terms"
                                        type="checkbox"
                                        checked={cvOptIn}
                                        onChange={() => setCvOptIn(!cvOptIn)}
                                    />
                                    <span className="pl-2 text-white">Με την επισύναψη του βιογραφικό σας συναινείτε στην αποθήκευση και επεξεργασία των προσωπικών σας δεδομένων για χρονικό διάστημα 4 μηνών από την Enimeris καθώς και την κοινοποίηση τους στους εργοδότες για τους οποίους έχετε εκδηλώσει ενδιαφέρον και για πιθανούς εργοδότες για ανάλογες θέσεις. *</span>
                                </div>
                            )}

                        </div>
                        {(canSubmit && canSubmit2)
                            ?
                            <button className={"bttn-active cf-bttn"}>
                                Υποβολή
                            </button>
                            :
                            <button className={"cf-bttn"} onClick={(e) => test(e)}>
                                Υποβολή
                            </button>
                        }
                        {showErrorMessage && (
                            <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Συμπληρώστε όλα τα απαραίτητα πεδία (*)</p>
                        )}
                    </Formsy>
                    :
                    <div >
                        <div className="email-response" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                    </div>
                }

            </div>
        </div>
    )
}

export default CareerDayForm;