import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import CareerDayForm from "../components/Global/CareerDayForm"
import Faq from "react-faq-component";


import _ from 'lodash'
import Img from "gatsby-image"
import Footer from "../components/FooterMenu"


import logoWhite from "../images/enimeris-logo-website-white.png";
import "./../components/index.css"

const styles = {
    // bgColor: 'white',
    titleTextColor: "#000",
    rowTitleColor: "#000",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};


function CareerDays(props) {
    const [faqsData, setFaqsData] = useState()
    const data = props.data.wpgraphql


    useEffect(() => {
        let tmpFaqs = {
            title: "",
            rows: []
        }

        tmpFaqs.title = ""

        data.page.careerDays.section5.faqs.map((fq, i) => {
            tmpFaqs.rows.push({
                title: fq.question,
                content: fq.answer
            })
        })

        setFaqsData({...tmpFaqs})

    },[])

    console.log(data)
    return (
        <div>

            <Helmet>
                <html lang={"el"} />
                <title>{data.page.seo.title}</title>
                <meta name="description" content={data.page.seo.description} />
                <link rel="canonical" href={data.page.seo.canonical} />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />
                <meta property="og:title" content={data.page.seo.opengraphTitle} />
                <meta property="og:description" content={data.page.seo.opengraphDescription} />
                <meta property="og:image" content={data.page.seo.opengraphImage ? data.page.seo.opengraphImage.sourceUrl : ""} />
                <meta property="og:image:width" content="598" />
                <meta property="og:image:height" content="399" />
            </Helmet>

            <div>
                <div className="text-white synergates-intro flex justify-center">
                    <Link to={"/"}>
                        <img className="w-56 md:w-64 m-auto py-8" src={logoWhite} alt="white-logo" />
                    </Link>
                </div>

                {/* Intro */}
                <div>
                    <div className="background-home-container flex-wrap relative">
                        <div className="home-img-wrapper block" style={{ padding: 0 }}>
                            <picture>
                                {/* <source type="image/webp" srcSet={data.mobBackImg.imageFile.childImageSharp.fluid.srcSetWebp +"," + data.backImg.imageFile.childImageSharp.fluid.srcSetWebp} className="home-intro-bg" /> */}
                                <source type="image/webp" srcset={data.page.careerDays.intro.image.imageFile.childImageSharp.fluid.srcSetWebp} media="(min-width: 601px)" />
                                {/* <source type="image/webp" srcset={data.introductionBackgroundImageMobile.imageFile.childImageSharp.fluid.srcSetWebp} media="(max-width: 600px)" importance="high" /> */}
                                <source type="image/jpg" srcset={data.page.careerDays.intro.image.imageFile.childImageSharp.fluid.srcSet} media="(min-width: 601px)" />
                                {/* <source type="image/jpg" srcset={data.introductionBackgroundImageMobile.imageFile.childImageSharp.fluid.srcSet} media="(max-width: 600px)" /> */}
                                <img
                                    srcSet={data.page.careerDays.intro.image.imageFile.childImageSharp.fluid.srcSetWebp}
                                    style={{ objectFit: "cover", width: "100%", height: "100%" }}
                                    alt={data.page.careerDays.intro.image.altText}
                                />
                            </picture>
                        </div>
                        <div className="flex flex-wrap justify-center items-center bg-home-wrapper container">
                            <div className="p-4 w-full sm:w-2/3">
                                <div className="px-0 sm:px-12 text-center sm:text-left custom-fonts-n">
                                    <div className="home-h1 sm:text-4xl md:text-5xl" dangerouslySetInnerHTML={{__html:data.page.careerDays.intro.title}}>
                                    </div>
                                    <div className="home-main-desc-new py-4 text-2xl sm:text-2xl" dangerouslySetInnerHTML={{__html:data.page.careerDays.intro.subtitle}} />
                                    <p className="text-2xl sm:text-2xl italic">Με την ευγενική υποστήριξη του Δήμου Παγγαίου</p>
                                    <img className="m-auto md:m-0" src="https://f2.enimeris.com/wp-content/uploads/2022/03/Paggaio-logo.png" alt="pageo-logo" style={{ maxWidth: "330px" }} />
                                    <a href={"#formApplP"} className="main-blue-b" style={{ display: "inline-block" }}>{data.page.careerDays.intro.cta.title}</a>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/3 flex justify-center">
                            </div>
                        </div>
                        <div className="home-bg-overlay"></div>
                    </div>
                </div>

                <section className="py-16 px-4">
                    <div className="flex flex-wrap container items-center mx-auto w-full">
                        <div className="w-full md:w-2/5 pr-0 md:pr-8">
                            <Img fluid={data.page.careerDays.section2.image.imageFile.childImageSharp.fluid} alt={data.page.careerDays.section2.image ? data.page.careerDays.section2.image.altText : ""} />
                        </div>
                        <div className="w-full md:w-3/5 pl-0 md:pl-8 pt-4 md:pt-0">
                            <div className="s2-ce" dangerouslySetInnerHTML={{ __html: data.page.careerDays.section2.content }} />
                        </div>

                    </div>
                </section>

                <section className="hidden md:flex items-center" style={{background: `url(${data.page.careerDays.section10.desktopImage.sourceUrl})`, minHeight: "235px"}}> 
                    <div className="flex justify-between items-center container mx-auto">
                        <div className="cta-cr-editor" dangerouslySetInnerHTML={{__html: data.page.careerDays.section10.content}}>

                        </div>
                        <div>
                            <Link to={"/" + data.page.careerDays.section10.cta.link} className="cta-r-btn" style={{ display: "inline-block" }}>{data.page.careerDays.section10.cta.text}</Link>
                        </div>
                    </div>
                </section>

                <section className="flex md:hidden items-center" style={{background: `url(${data.page.careerDays.section10.mobileImage.sourceUrl})`, minHeight: "500px", backgroundSize: "cover"}}> 
                    <div className="flex flex-wrap justify-between items-center container mx-auto px-4">
                        <div className="cta-cr-editor w-full text-center" dangerouslySetInnerHTML={{__html: data.page.careerDays.section10.content}}>

                        </div>
                        <div className="w-full flex justify-center pt-8">
                            <Link to={"/" + data.page.careerDays.section10.cta.link} className="cta-r-btn" style={{ display: "inline-block" }}>{data.page.careerDays.section10.cta.text}</Link>
                        </div>
                    </div>
                </section>

                <section className="py-16 px-4" style={{ backgroundColor: "#fafcff" }}>
                    <div className="flex flex-wrap container items-center mx-auto w-full">
                        <div className="w-full md:w-3/5 pr-0 md:pr-8 ">
                            <div className="s2-ce" dangerouslySetInnerHTML={{ __html: data.page.careerDays.section3.content }} />
                        </div>
                        <div className="w-full md:w-2/5 pl-0 md:pl-8 pt-4 md:pt-0">
                            <Img fluid={data.page.careerDays.section3.image.imageFile.childImageSharp.fluid} alt={data.page.careerDays.section3.image ? data.page.careerDays.section3.image.altText : ""} />
                        </div>
                    </div>
                </section>

                {(data.page.careerDays.section4 && data.page.careerDays.section4.length > 0) && data.page.careerDays.section4.map((s4, i) => (
                    <section key={`s4-${i}`} className={`py-16 px-4`} style={i === 0 ? { backgroundColor: "#ffffff" } : { backgroundColor: "#fafcff" }}>
                        <div className={"flex flex-wrap container items-center mx-auto w-full"}>
                            <div className={`w-full md:w-2/5  ${i === 0 ? "order-1 pr-0 md:pr-8" : "order-2 pl-0 md:pl-8"}`}>
                                <Img fluid={s4.image.imageFile.childImageSharp.fluid} alt={s4.image ? s4.image.altText : ""} />
                            </div>
                            <div className={`w-full md:w-3/5 pt-4 md:pt-0 ${i === 0 ? "order-2 pl-0 md:pl-8" : "order-1 pr-0 md:pr-8"}`}>
                                <div className="s2-ce" dangerouslySetInnerHTML={{ __html: s4.content }} />
                            </div>

                        </div>
                    </section>
                ))}

                <section id="faqs">
                    <div className="container mx-auto py-12 s2-ce px-4">
                        <h2 className="text-center pb-4">{data.page.careerDays.section5.title}</h2>
                        <Faq
                            data={faqsData}
                            styles={styles}
                            config={config}
                        />

                    </div>
                </section>

                <section>
                    <div className='py-12 md:py-12' style={{backgroundColor: "#fafcff"}}>
                        <div className='container m-auto s2-ce' >
                            <h2 className="text-center pb-4">{data.page.careerDays.section7.title}</h2>

                            <div className='w-full flex flex-wrap justify-center'>
                                {(data.page.careerDays.section7 && data.page.careerDays.section7.sponsors.length > 0) && data.page.careerDays.section7.sponsors.map((brand, i) => (
                                    <a href={brand.link} target="_blank" className='w-full  md:w-1/2 lg:w-1/4 relative brand-link-3'>
                                        <div className="flex justify-center">
                                            <Img fixed={brand.image.imageFile.childImageSharp.fixed} alt={brand.image ? brand.image.altText : ""} />
                                        </div>
                                    </a>
                                ))}

                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className='py-12 md:py-12' style={{backgroundColor: "#fafcff"}}>
                        <div className='container m-auto s2-ce' >
                            <h2 className="text-center pb-4">{data.page.careerDays.section8.title}</h2>

                            <div className='w-full flex flex-wrap justify-center'>
                                {(data.page.careerDays.section8 && data.page.careerDays.section8.sponsors.length > 0) && data.page.careerDays.section8.sponsors.map((brand, i) => (
                                    <a href={brand.link} target="_blank" className='w-full md:w-1/2 lg:w-1/4 relative brand-link-3'>
                                        <div className="flex justify-center">
                                            <Img fixed={brand.image.imageFile.childImageSharp.fixed} alt={brand.image ? brand.image.altText : ""} />
                                        </div>
                                    </a>
                                ))}

                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className='py-12 md:py-12' style={{backgroundColor: "#fafcff"}}>
                        <div className='container m-auto s2-ce' >
                            <h2 className="text-center pb-4">{data.page.careerDays.section9.title}</h2>

                            <div className='w-full flex flex-wrap justify-center'>
                                {(data.page.careerDays.section9 && data.page.careerDays.section9.sponsors.length > 0) && data.page.careerDays.section9.sponsors.map((brand, i) => (
                                    <a href={brand.link} target="_blank" className='w-full md:w-1/2 lg:w-1/4 relative brand-link-3'>
                                        <div className="flex justify-center">
                                            <Img fixed={brand.image.imageFile.childImageSharp.fixed} alt={brand.image ? brand.image.altText : ""} />
                                        </div>
                                    </a>
                                ))}

                            </div>
                        </div>
                    </div>
                </section>

                <section id="formApplP" className="pt-12">
                    <CareerDayForm 
                        allowPhoneNumber={true} 
                        allowCv={true} 
                        textData={data.page.careerDays.section6}
                        jobCompanies={data.jobCompanies}
                    />
                </section>


            </div>
            <Footer footerMenu={data.footer} />
        </div>
    )
}


export default CareerDays

export const pageQuery = graphql`
	query {
		wpgraphql {
			page(id: "cG9zdDo1NjU0") {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }

                careerDays{
                    intro{
                      title
                      subtitle
                      image{
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2080, quality:100){
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                      }
                      cta{
                        title
                        url
                      }
                    }
                    section2{
                      content
                      image{
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 504, quality:100){
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                      }
                    }
                    section3{
                      content
                      image{
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 504, quality:100){
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                      }
                    }
                    section4{
                      content
                      image{
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 504, quality:100){
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                      }
                    }
                    section5 {
                        title
                        faqs{
                            question
                            answer
                        }
                    }
                    section6{
                      title
                      subtitle
                      cta{
                        title
                      }
                      applicationPositions{
                        position
                      }
                    }
                    section7{
                        title
                        sponsors {
                            image{
                                altText
                                sourceUrl
                                imageFile{
                                    childImageSharp{
                                        fixed(width: 300 , height: 300){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                            link
                        }
                    }

                    section8{
                        title
                        sponsors {
                            image{
                                altText
                                sourceUrl
                                imageFile{
                                    childImageSharp{
                                        fixed(width: 300 , height: 300){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                            link
                        }
                    }

                    section9{
                        title
                        sponsors {
                            image{
                                altText
                                sourceUrl
                                imageFile{
                                    childImageSharp{
                                        fixed(width: 300 , height: 300){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                            link
                        }
                    }

                    section10{
                        content
                        cta{
                          text
                          link
                        }
                        desktopImage{
                            altText
                            sourceUrl
                            imageFile{
                                childImageSharp{
                                    fluid(maxWidth: 1920, quality:100){
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            }
                        }
                        mobileImage{
                            altText
                            sourceUrl
                            imageFile{
                                childImageSharp{
                                    fluid(maxWidth: 480, quality:100){
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            }
                        }
                    }
                    
                }
            }

            jobCompanies(first:100){
                edges{
                    node{
                        name
                        slug
                        ourJobs(first:100){
                        edges{
                            node{
                            title
                            slug
                            }
                        }
                        }
                    }
                }
            }

            footer: menu(id:"bmF2X21lbnU6MjQ="){
                menuId
                id
                slug
                name
                menuItems {
                    edges {
                        node {
                            label
                            url
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
		}
	}
`
